import React, { useEffect, useState } from "react"
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import "./ImgViewer.css"



interface PropShape {
    children: React.ReactNode[];
}

export default (props: PropShape) => {
    const [slide, setSlide] = useState<number>(0);

    const back = () => {
        if (props.children[slide + 1]) {
            setSlide(slide + 1)
        } else {
            setSlide(0)
        }

    }

    const forward = () => {
        if (props.children[slide - 1]) {
            setSlide(slide - 1)
        } else {
            setSlide(props.children.length - 1)
        }
    }

    return (
        <div className="ImgViewer">
            <h2 className="fancy">Some images</h2>
            <h3 className="fancy">Download full resolution below</h3>
            <button onClick={back} style={{ left: 0 }}><IoIosArrowBack /></button>
            <button onClick={forward} style={{ right: 0 }}><IoIosArrowForward /></button>
            {props.children[slide]}
        </div>
    )
}