import React from "react";
import ImgViewer from "./ImgViewer";

import "./Rocket.css";
import r1 from "../../images/rocket/imgone.jpg"
import r2 from "../../images/rocket/imgtwo.jpg"
import r3 from "../../images/rocket/imgthree.jpg"
import r4 from "../../images/rocket/imgfour.jpg"
import r5 from "../../images/rocket/imgfive.jpg"
import r6 from "../../images/rocket/imgsix.jpg"


const slides = [
    {
        render: <p>Hei</p>,
        id: 1,
    },
    {
        render: <p>Hello</p>,
        id: 2,
    },
]

export default () => {

    const downloadFiles = (dataurl: string, filename: string) => {
        var a = document.createElement("a");
        a.href = dataurl;
        a.click();
    }


    return (
        <div className="Rocket">

            <header>
                <h1 className="fancy">It's just Rocket Science</h1>
                <p className="fancy">A lot more than a school project presented to you by Daniel and Benjam.Tech</p>
            </header>

            <div className="section">
                <ImgViewer>
                    <img alt="datalogger parts" src={r1}></img>
                    <img alt="rocket parts" src={r2}></img>
                    <img alt="whole rocket" src={r3}></img>
                    <img alt="rocket painted almost" src={r4}></img>
                    <img alt="datalogger again" src={r5}></img>
                    <img alt="rocket fins" src={r6}></img>
                </ImgViewer>
            </div>

            <div className="data section">
                <div className="limiter">
                    <div>
                        <h2 className="fancy">Dataset</h2>
                        <p>Download our complete dataset, including datalogger-code, images, prototyping, and design</p>
                    </div>

                    <div className="download">
                        <p>rocketFiles.zip</p>
                        <button onClick={() => {
                            downloadFiles("https://rocketbucket.s3.eu-north-1.amazonaws.com/rocketFiles.zip", "rocketFiles.zip")
                        }}>Download</button>
                    </div>

                </div>

            </div>
        </div>
    )
}